






































import Vue, { PropType } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  ASSESSMENT_ERROR,
  ASSESSMENT_QUESTIONS,
  TENANT_INFO
} from "@/store/modules/candidates/constants";
import { AssessmentErrorTypes } from "@/store/modules/candidates/interfaces";
import {
  GET_ASSESSMENT_SUMMARY,
  GET_ROLE_BY_NAME
} from "@/store/modules/common/constants";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";

export default Vue.extend({
  name: "AssessmentError",
  computed: {
    AssessmentErrorTypes() {
      return AssessmentErrorTypes;
    },
    ...mapGetters("candidate", {
      tenant_info: TENANT_INFO
    }),
    ...mapGetters("common", {
      get_role_by_name: GET_ROLE_BY_NAME
    }),
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS
    })
  },
  props: {
    error_type: {
      type: Number as PropType<AssessmentErrorTypes>,
      required: true
    }
  },
  methods: {
    ...mapMutations("candidate", {
      assessment_error: ASSESSMENT_ERROR
    }),
    ...mapActions("candidate", {
      fetch_assessment_questions: ASSESSMENT_QUESTIONS
    }),
    ...mapActions("common", {
      fetch_assessment_summary: GET_ASSESSMENT_SUMMARY
    }),
    async refresh_assessment_questions() {
      this.assessment_error(false);
      if (this.error_type === AssessmentErrorTypes.QUESTIONS)
        await this.fetch_assessment_questions();
      else if (this.error_type === AssessmentErrorTypes.REPORT) {
        this.$emit("refresh_candidate_report");
      }
    }
  }
});
